import React, { Suspense } from "react";
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Route } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./sample/lib/config";
import { TeamsFxContext } from "./Context";

// Lazy import of components
const Privacy = React.lazy(() => import("./About/Privacy"));
const TermsOfUse = React.lazy(() => import("./About/TermsOfUse"));
const TabLanding = React.lazy(() => import("./TabLanding"));
const TabConfig = React.lazy(() => import("./TabConfig"));
const Configuration = React.lazy(() => import("./Configuration/Configuration"));
const ViewExpenses = React.lazy(() => import("./Approval/ViewExpenses"));
const AddExpenseForm = React.lazy(() => import("./Expense/AddExpenseForm"));
const EditExpenseForm = React.lazy(() => import("./Expense/EditExpenseForm"));
const AllExpenses = React.lazy(() => import("./Expense/AllExpenses"));
const Home = React.lazy(() => import("./About/homepage"));

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <Provider theme={ teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
        <Router>
          {loading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <Suspense fallback={<Loader style={{ margin: 100 }} />}>
              <Route exact path="/" component={Home} />
              <Route exact path="/about/privacy" component={Privacy} />
              <Route exact path="/about/termsofuse" component={TermsOfUse} />
              <Route exact path="/newrequest" component={AddExpenseForm} />
              <Route exact path="/tab" component={TabLanding} />
              <Route exact path="/allrequest" component={AllExpenses} />
              <Route exact path="/approval" component={ViewExpenses} />
              <Route exact path="/settings" component={Configuration} />
              <Route exact path="/config" component={TabConfig} />
              <Route exact path="/editrequest" component={EditExpenseForm} />
            </Suspense>
          )}
        </Router>
      </Provider>
    </TeamsFxContext.Provider>
  );
}
